body {
  background-color: #eff4f7;
  color: #777;
  font-family: 'Titillium Web', Arial, Helvetica, sans-serif;
}

.box {
  max-height: 444px;
}

.box {
  box-shadow: 0px 1px 22px -12px #607d8b;
  background-color: #fff;
  border-radius: 25px;
  padding: 25px 35px 25px 30px;
  min-height: 300px;
  margin: 'auto';
}

.mediumBox {
  box-shadow: 0px 1px 22px -12px #607d8b;
  background-color: #fff;
  border-radius: 25px;
  padding: 25px 35px 25px 30px;
  min-height: 450px;
  margin: 'auto';
}
.smallBox {
  box-shadow: 0px 1px 22px -12px #607d8b;
  background-color: #fff;
  border-radius: 25px;
  padding: 25px 35px 25px 30px;
  margin: 'auto';
}
.standartStack {
  padding: 30;
}

@media screen and (max-width: 760px) {
  .box {
    padding: 25px 0;
  }
}

.loading {
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  display: inline-block;
  clip-path: inset(0 1.5ch 0 0);
  animation: l 1.2s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}
